<template>
  <div>
    <AuthLayout :wide="true">
      <div class="mb-2">
        <img src="../../../assets/icons/email.svg" alt="email-icon" />
      </div>
      <h1 class="mb-1">Verify your Email</h1>
      <span class="mb-2" style="display: block; color: #555555">
        Please enter your 6-digit verification code below sent to you at the
        email provided. If you have not yet received an email from Infohob,
        check your <strong> Spam</strong> folder, or try resending. If you have
        entered wrong number, you can edit it by clicking the Pencil icon below.
      </span>

      <div class="flex flex__center mb-4">
        <div class="email__paste flex flex__item-center">
          <p class="mr-1">{{ userData.user.email }}</p>
          <button class="cursor-pointer flex flex__center">
            <img src="../../../assets/icons/copy-icon.svg" alt="copy-icon" />
          </button>
        </div>
      </div>

      <div class="flex flex__center mb-5">
        <div class="w-30 grid grid__layout gap-1 flex__center">
          <div class="col-2 otp__box" v-for="(n, index) in 6" :key="index">
            <input
              type="tel"
              class="otp__input"
              placeholder="-"
              maxlength="1"
              @input="setText($event, index + 1)"
            />
          </div>
        </div>
      </div>

      <div class="flex flex__center mb-2">
        <div class="w-23">
          <Button
            @click="activateUser"
            :disabled="buttonDisabled || loading"
            :loading="loading"
            theme="blue"
          >
            Verify
          </Button>
        </div>
      </div>
      <!-- <div class="flex flex__center">
        <p
          style="background: #fafafa; color: #8b90a0; border-radius: 4px"
          class="p-1"
        >
          00:{{ resendOTPIn | fixZero }}
        </p>
      </div> -->

      <div class="flex flex__center mt-4">
        <div class="w-23">
          <Button
            @click="resendOTP"
            :disabled="resendOTPIn > 0"
            theme="blue"
            :loading="loadingOTP"
          >
            Resend OTP
            <template v-if="resendOTPIn > 0">
              in 00:{{ resendOTPIn | fixZero }}
            </template>
          </Button>
        </div>
      </div>
    </AuthLayout>
  </div>
</template>

<script>
import AuthLayout from "../../../layouts/AuthLayout";
import Button from "../../../components/Button";
import API from "../../../const";
import { mapActions, mapGetters, mapMutations } from "vuex";
import authService from "../../../services/api/authService";
import { setPrivateHaders } from "../../../services";

export default {
  name: "VerifyEmail",
  components: {
    AuthLayout,
    Button
  },
  data() {
    return {
      OTP: [],
      loading: false,
      resendOTPIn: 0,
      loadingOTP: false
    };
  },
  filters: {
    fixZero(value) {
      return value < 10 ? "0" + value : value;
    }
  },
  mounted() {
    localStorage.setItem("otpReceivedAt", Date.now());
    document.querySelector(".otp__input").focus();
    this.calculateResendOTPIn();
  },
  computed: {
    ...mapGetters({
      userData: "authnew/getUserData"
    }),
    buttonDisabled() {
      return this.OTP.some((digit) => !digit) || this.OTP.join("").length !== 6;
    }
  },
  methods: {
    ...mapActions({
      verifyUser: "apimodel/makeRequest",
      sendOTP: "apimodel/makeRequest"
    }),
    ...mapMutations({
      setUserData: "authnew/setUserData"
    }),
    setText({ target: { value } }, position) {
      const OTPinputs = document.querySelectorAll(".otp__input");

      // Targets the last input
      if (OTPinputs.length === position && value.length <= 1) {
        this.OTP = [...this.OTP];
        this.OTP[position - 1] = value;
      }

      // Targets all the inputs except the last
      if (OTPinputs.length !== position && value.length <= 1) {
        this.OTP = [...this.OTP];
        this.OTP[position - 1] = value;

        if (value.length === 1) {
          OTPinputs[position].focus();
          OTPinputs[position].setSelectionRange(0, 1);
        }
      }
    },
    activateUser() {
      this.loading = true;

      authService
        .verifyEmail({ otp: this.OTP.join("") }, this.userData.token)
        .then((res) => {
          this.$handleSuccess(res);
          this.setUserData(res.data);
          setPrivateHaders(res.data.token);
          setTimeout(() => {
            this.$router.push({
              name: "onboarding"
            });
          }, 2000);
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // resendOTP() {
    //   // To Do -> Call Resend OTP API
    //   this.resendOTPIn = 59;
    // },

    resendOTP() {
      this.loadingOTP = true;
      authService
        .resendOtp()
        .then((res) => {
          localStorage.setItem("otpReceivedAt", Date.now());
          this.$handleSuccess(res);
          this.calculateResendOTPIn();
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.loadingOTP = false;
        });
    },
    calculateResendOTPIn() {
      let otpReceivedAt = localStorage.getItem("otpReceivedAt");
      if (!otpReceivedAt) {
        this.resendOTPIn = 59;
      }
      if (otpReceivedAt) {
        let timeDiff = Math.ceil((Date.now() - parseInt(otpReceivedAt)) / 1000);

        this.resendOTPIn = 60 - timeDiff;
      }
    }
  },

  watch: {
    resendOTPIn: function (newValue) {
      if (newValue > 0) {
        setTimeout(() => {
          this.resendOTPIn -= 1;
        }, 1000);
      } else if (newValue < 0) {
        this.resendOTPIn = 0;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.email__paste {
  padding: 0.6rem 5rem;
  border-bottom: 1px solid #dddddd;
}

.otp {
  &__box {
    border-bottom: 1px solid #dddddd;
    padding: 1rem;
  }

  &__input {
    width: 100%;
    border: none;
    outline: none;
    text-align: center;

    &::placeholder {
      color: #555555;
    }
  }
}
</style>
